<template>
  <div class="flex flex-col mb-4">
    <label class="block">
      <div
        class="mb-3 text-xs font-bold tracking-wide text-gray-900 sm:text-sm"
      >
        {{ name ? name : 'Password' }}
      </div>
      <div class="relative">
        <div
          class="absolute top-0 left-0 flex w-10 h-full border border-transparent"
        >
          <div
            class="z-10 flex items-center justify-center w-full h-full text-lg text-gray-600 bg-gray-100 rounded-tl rounded-bl"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
              ></path>
            </svg>
          </div>
        </div>

        <input
          :type="typeProxy"
          placeholder="Password"
          :value="value"
          @input="$emit('input', $event.target.value)"
          class="relative w-full py-2 pl-12 pr-2 text-sm placeholder-gray-400 border rounded sm:text-base focus:border-gray-800 focus:outline-none"
        />
        <div class="eye-body" @click="onTogglePasswordField">
          <i v-if="typeProxy === 'text'" class="far fa-eye" />
          <i v-if="typeProxy === 'password'" class="far fa-eye-slash" />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'PasswordField',
  props: {
    value: {
      required: false,
    },
    name: {
      required: false,
    },
  },
  data() {
    return {
      typeProxy: 'password',
    }
  },
  methods: {
    onTogglePasswordField() {
      this.typeProxy = this.typeProxy === 'password' ? 'text' : 'password'
    },
  },
}
</script>
<style lang="scss" scoped>
.eye-body {
  position: absolute;
  top: 0px;
  right: 0px;
  top: 8px;
  right: 11px;
  cursor: pointer;
  i {
    @apply text-gray-700;
  }
}
</style>
